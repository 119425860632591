@media only screen and (max-width: 1024px) {
    .hide-mobile {
        display: none;
    }
}
@media only screen and (min-width: 768px) {

    .search-icon-mobile, .malaysia-decides-banner-mobile {
        display: none;
    }

    
    .main-menu-mobile {
        display: none;   
    }

    .modal {
        display: none !important;
    }
}
@media only screen and (max-width: 767px) {
    .container {
        max-width: 100%;
        padding-right: 10px;
        padding-left: 14px;
    }

    .search-icon-mobile {
        display: none;
    }

    .navbar-main {
        padding: 0px !important;
        margin-top: 50px;
    }

    .navbar-main-inner {
        margin-top: 80px;
    }
    .main-logo {
        position: absolute;
        margin-top: -60px;
        margin-left: 15px;
        width: 50px  !important;
    }

    .top-ticker .none {
        display: none;
    }

    .front-carausel {
        display: none;
    }

    .malaysia-decides-banner {
        display: none;
    }
    
    .malaysia-decides-banner-mobile {
        width: 90px;
        float: left;
        margin-top: -60px;
        margin-left: 43%;
    }

    .search-icon-mobile {
        width: 30px;
        float: right;
        margin-top: -50px;
        margin-right: 35px;
    }

     .main-menu-right{
        display: none;
    }

    .main-menu-mobile {
        margin-left: 0px;
        font-weight: 700;
        font-family: Helvetica, sans-serif;
        display: flex;
        gap: 10%;
        padding-left: 0px;
    }

    .main-menu-mobile a {
        font-size: 12px !important;
        padding: 16px 0px !important;
    }

    .main-menu li {
        padding: 0px;
        margin-right: 2px !important;
    }

    .main-menu-desktop {
        display: none;
    }

    .decision-content-title {
        font-size: 16px;
        text-align: center;
        padding-top: 6px !important;
    }

    .decision-content-title2 {
        text-align: center;
        padding: 3px 30px 3px 3px;
    }

    .middle-menu {
        display: none !important;
    }

    .decision-detail-item {
        font-size: 12px !important;
        padding: 5px;
        padding-top: 25px;
    }

    .col{
        padding-top: 8px !important;
        text-align: center;
    }

    .col1 {
        flex:1 0 8% !important;
        text-align: center;
    }

    .decision-detail-item .decision-count {
        font-size: 14px !important;
    }

    .abbr-item, .abbr-title, .abbr-title, .abbr-count, .abbr-count2  {
        float: left;
    }
    
    .abbr-wrapper {
        padding: 15px 25px;
    }
    
    .abbr-item {
        padding: 10px;
        
    }
    
    .abbr-title {
        font-size: 32px;
        font-weight: 600;
        color: #fff;
        padding:4px 20px;
        text-align: center;
    }


    .abbr-item .abbr-title, .abbr-item {
        font-size: 18px !important;
    }

    .abbr-count  {
        padding: 0px;
        margin-left: 8px;
        color: #000;
        font-weight: 600;
        font-size: 2.2vh !important;
        line-height: 37px !important;
    }

    .abbr-count2  {
        padding: 0px;
        margin-top: -8px;
        margin-left: 20px;
        color: #000;
        font-weight: 600;
        font-size: 50px !important;
        text-align: center;
    }

    .decision-search-wrapper, .middle-menu{
        display: none;
    }

    .decision-content-wrapper {
        margin-top: -10px !important;
    }

    .hide-mobile {
        display: none;
    }

    .main-menu-mobile li ul {
        position: absolute;
        background-color: #dfe0e0;
        display: none;
        z-index: 99;
    }
    
    .main-menu-mobile li ul li a {
        color: #000 !important;
    }
    .main-menu-mobile li ul li {
        width: 30%;
    }
    .main-menu-mobile li ul.full-menu li {
        width: 100%;
    }

    .main-menu-mobile li:hover ul {
        display: block;
    }
    .position-wrapper {
        border-right: none  !important;
    }

    .candidate-decision-map-wrapper {
        display: none;
    }

    .btn-detail-senarai-selangor {
        color: #d70000;
    }

    .parlement-wrapper .parlement-logo img{
        width: 40px;

    }

    .parlement-count {
        font-size:18px;
    }

    .candidate-wrapper {

    }
    
    .candidate-name {
        font-size: 14px;
        line-height: 18px;
        padding-bottom: 2px;
        text-align: left;

    }

    .candidate-total-vote {
        font-size: 20px;
        margin-top: 13px;
        margin-left: -170px;
    }

    .parlement-count {

    }

    .decision-list-overlay {
        display: none;
    }

    .decision2-list-overlay {
        position: relative;
        z-index: 999988;
        top: 0px;
        min-height: 377px;
        width: 100%;
    }

    .check-image {
        width: 42px;
        margin-left: 130px;
        margin-top: -40px;
    }

    .title-majority {
        font-size: 12px;
        margin-left: -60px;
        margin-top: 3vmax;
    }

    .candidate-wrapper-detail {
        float: right;
        margin-top: -10px;
    }   

    .decision-content-title .close {
        margin-right: -25px;
    }

    .decision-content-title .prev {
        margin-top: -2px;
        margin-left: 2px;
    }

    .decision-content-title2 .close {
        margin-right: -25px;
    }

    .decision-content-title2 .prev {
        margin-top: -2px;
        margin-left: 2px;
    }

    .decision-progress-wrapper {
        margin-top: 0px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .progress {
        border-radius: 0px;
        height: 20px;
    }

    .body-title-1 {
        text-align: center;
        margin-top: 20px;
        font-size: 16px;
    }
    
    .body-title-2 {
        text-align: center;
        font-weight: 600;
        font-size: 18px;
    }

    .berita-pru-top {
        background-color: #4aaaa1;
        height: 80px;
        margin-top: 40px;
        width: 97%;
        float: left;
        color: #fff;
        font-weight: 800;
        font-size: 40px;
        text-align: center;
        padding-top: 12px;
        margin-left: 1.4%;
        margin-right: 1.4%;
     }

    .hot-news-wrapper {
        margin-top:-20px;
    }
    
    .section-raya {
        border: 3px solid #c12c1a;
        padding: 0px;
        border-left: none;
        border-right: none;
        margin-top: 0px;
        margin-bottom: 25px;
        text-align: center;
        font-size: 18px;
        font-weight: bolder;
        color: #10376c;
        font-family:"Gotham Black";
    }

    .copyrightWall {
        display: none;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: flex;
    }
  }