@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap);
body {
  font-family: 'Comfortaa', cursive;
}

main {
  min-height: 80vh;
}

body {
    font-family: Arial, Helvetica, sans-serif;
}
.navbar-main, .navbar-main-inner{
    background-color: #4aaaa1   !important;
    height: 50px;
    margin-top: -16px;
}

.navbar-main {
    padding-left: 8px;
}

.middle-menu {
    list-style: none;
    margin-top: 15px;
    margin-left: 0px;
    height: 40px;
}

.middle-menu a:hover {
    opacity: 0.8;
}

.middle-menu .menu-item {
    border-right: 2px solid #c12c1a;
    display: inline-block;
    margin-bottom: 10px;
    width: auto !important;

}

.middle-menu .menu-item-last {
    border-right: 2px solid #ffffff;
    display: inline-block;
    margin-bottom: 10px;
    width: auto !important;

}

.middle-menu .menu-item a {
    text-decoration: none;
    display: inline-block;
    font-size: 11.2px;
    font-weight: 500;
    color: #000;
    font-family:  Helvetica, sans-serif;
}

.middle-menu .menu-item-last a {
    text-decoration: none;
    display: inline-block;
    font-size: 11.2px;
    font-weight: 500;
    color: #000;
}

.main-menu {
    list-style: none;
    margin-left: 50px;
}

.main-menu li {
    list-style: none;
    float: left;
    margin-right: 40px;
}


.main-menu li a {
    text-decoration: none;
    display: block;
    color: #fff !important;
    padding: 15px;
    font-size: 15px;
    font-weight: 600;
}


.main-menu-right {
    list-style: none;
    margin-left: 50px;
    margin-right: 0px;
}

.main-menu-right li {
    list-style: none;
    float: right;
    margin-right: 10px;
    margin-top: 10px;
}


body, html {
    margin: 0px !important;
    padding: 0px !important;
}

.main-logo {
    position: absolute;
    width: 75px;
}

.running-info-wrapper {
    background-color: #62767d !important;
    height: 32px;
    padding: 5px;
    color: #fff;
    font-size: 14px;
}

.section-raya {
    border: 5px solid #c12c1a;
    padding: 0px;
    border-left: none;
    border-right: none;
    margin-top: 15px;
    text-align: center;
    font-size: 30px;
    font-weight: bolder;
    color: #10376c;
    font-family:"Gotham Black";
}

.section-raya span {
    color: #c12c1a;
}

.red-indicator-running {
    color: #fff;
    font-size: 14px;
    font-weight: bolder;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #c12c1a;
    width: 75px;
    height: 32px;
    position: absolute;
    z-index: 9;

}

.front-carausel {
    float: left;
    width: 35%;
    margin-top: 40px;
    padding-right: 0px;
    padding-left: 20px;
    height: 205px !important;
}

.pr-0 {
    padding-right: 0px;
}

.control-arrow {
    background-color: #4aaaa2 !important;
    color: #fff;
    opacity: 0.4 !important;
    top: 40%!important;
    bottom: auto !important;
}

.fakta-menarik-overlay {
    height: 205px;
    background-color: #565f5f !important;
    color: #fff;
    width: 100%;
    padding: 20px;
}

.overlay-slide-show {
    height: 207px;
    background-color: rgba(74,170, 162, 0.5);
    color: #fff;
    width: 100%;
    padding: 20px;
    position: absolute;
}

.overlay-slide-show2 {
    height: 207px;
    background-color: rgba(74,170, 162, 0);
    color: #fff;
    width: 100%;
    padding: 20px;
    position: absolute;
}

.malaysia-decides-banner {
    width: 30%;
    margin-top: 40px;
    float: left;
    height: 206px;
    padding-right: 0px;
}

.decision-content-wrapper {
    background-color: #dddfdf;
    position: relative;
}

.decision-content-title {
    background-color: #10376c;
    color: #fff;
    padding: 3px;
    font-weight: 600;
    font-size: 20px;
    position: relative;
}

.decision-content-title2 {
    background-color: #10376c;
    color: #fff;
    padding: 3px 30px 3px 450px;
    font-weight: 600;
    font-size: 20px;
    position: relative;
}

.space {
    background-color: transparent !important;
    color: #fff !important;
    width: 1px !important;
}

.style4 .decision-content-title {
    padding-left: 30px;
    text-align: center;
}

.style4 .decision-content-title2 {
    padding-left: 30px;
    text-align: center;
}

.decision-content-wrapper.style2 .decision-content-title {
    background-color: #10376c;
    padding: 3px 30px 3px 30px;
    text-align: center;
}

.decision-content-wrapper.style2 .decision-content-title2 {
    background-color: #10376c;
    padding: 3px 30px 3px 30px;
    text-align: center;
}

.bg-red {
    background-color: #c12c1a !important;
}

.bg-darkred {
    background-color: #c12b1aa3 !important;
}

.decision-content-wrapper.style3 .decision-content-title {
    background-color: #c12c1a !important;
    padding: 3px 30px 3px 30px  !important;
    text-align: center;
}
.decision-content-wrapper.style4 .decision-content-title:not(.inherit-bg) {
    background-color: #d32919 ;
    padding: 3px  !important;
    text-align: center;
    width: 50%;
    float: left;
}
.decision-content-wrapper.style5 .decision-content-title:not(.inherit-bg) {
    background-color: #d32919 ;
    padding: 3px  !important;
    text-align: center;
    width: 100%;
    float: left;
}
.decision-content-wrapper.style3 .decision-content-title2 {
    background-color: #c12c1a !important;
    padding: 3px 30px 3px 30px  !important;
    text-align: center;
}
.decision-content-wrapper.style4 .decision-content-title2:not(.inherit-bg) {
    background-color: #d32919 ;
    padding: 3px  !important;
    text-align: center;
    width: 50%;
    float: left;
}
.title-bg-navy {
    background-color: #10376c !important;
}
.title-bg-navy2 {
    background-color: #bdbdbd !important;
}
.decision-content-title .close {
    float: right;
    cursor: pointer;
    padding-right: 5px;
    position: relative;
}

.decision-content-title .prev {
    float: left;
    cursor: pointer;
}
.decision-content-title2 .close {
    float: right;
    cursor: pointer;
    padding-right: 5px;
    position: relative;
}

.decision-content-title2 .prev {
    float: left;
    cursor: pointer;
}

.decision-search-wrapper {
    float: right;
}

.decision-search-wrapper .line {
    -webkit-transform: rotate(40deg);
            transform: rotate(40deg);
    font-size: 40px;
    position: absolute;
    margin-left: -50px;
    margin-top: -20px;
    pointer-events: none;
}

.body-title-1 {
    text-align: center;
    margin-top: 20px;
    font-size: 20px;
}

.body-title-2 {
    text-align: center;
    font-weight: 600;
    font-size: 24px;
}

.abbr-item, .abbr-title, .abbr-title, .abbr-count {
    float: left;
}

.abbr-wrapper {
    padding: 15px 25px;
}

.abbr-item {
    padding: 15px;
    height: 100% !important;
}

.abbr-title, .abbr-count, .abbr-count2 {
    font-size: 40px;
    font-weight: 600;
    color: #fff;
    text-align: center;
}
.abbr-count {
    margin-left: 15px;
    color: #000;
    font-weight: 600;
    font-size: 3vh;
    line-height: 70px;
}
.abbr-title {
    background-color: #0e62b5;
}

.color-2 .abbr-title {
    background-color: #fff;
    color: #000;
}

.color-3 .abbr-title {
    background-color: #6fd8ee;
}

.color-4 .abbr-title {
    background-color: #565f5f;
}


.circle-sym {
    color: #c12c1a;
    margin-left: 20px;
    margin-right: 20px;
}


.running-info-wrapper .marquee-container {
    -webkit-align-items: baseline;
            align-items: baseline;
}

.decision-progress-wrapper {
    margin-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
}

.progress {
    border-radius: 0px;
    height: 40px;
}

.bg-bn {
    background-color: #0e62b5;
}.bg-gps {
    background-color: #ffffff;
}.bg-pkr {
    background-color: #6fd8ee;
}.bg-bebas {
    background-color: #565f5f;
}


.news-slide-title {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 800;
    
}
.news-slide-title a {
    text-decoration: none;
    color: #fff;
}
.news-slide-content {
    margin-top: 40px;
    font-size: 18px;
    font-weight: bolder;
    
    line-height: 20px;

}
.news-slide-footer {
    margin-top: 20px;
    font-size: 12px;
    line-height: 20px;

}

.decision-info-detail-wrapper {
    margin-top: 20px;
    padding-left: 20px !important;
    border-top: 3px solid #c12c1a ;
    
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.decision-detail-item {
    border-right: 3px solid #c12c1a ;
    margin-top: 10px;
    font-size: 24px;
    padding: 20px;
}
.decision-detail-item .decision-count {
    font-size: 30px !important;
    font-weight: bolder !important;

}

.decision-detail-item.no-border {
    border: none !important;
}

.decision-table-content {
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
}

.decision-table-content table td, .decision-table-content table th{
    background-color: #fff ;
}

.decision-table-content table tr:nth-child(odd) td {
    background-color: #f2f2f2;
}

.party-box-color {
    height: 24px;
    width: 100%;
    background-color: #565f5f;
}

.party-bg-1 {
    background-color: #73ceeb;
}
.party-bg-2 {
    background-color: #f4b118;
}
.party-bg-3 {
    background-color: #f06c6d;
}
.party-bg-4 {
    background-color: #2d5fab;
}
.party-bg-5 {
    background-color: #9c191d;
}
.party-bg-6 {
    background-color: #1fa64c;
}
.party-bg-7 {
    background-color: #503292;
}
.party-bg-8 {
    background-color: #b74d9c;
}
.party-bg-9 {
    background-color: #b7d231;
}
.party-bg-10 {
    background-color: #696cb1;
}

.social-icon {
    width: 20px !important;
    height: 20px !important;
    background-size: 140px !important;
  }

.search-icon {
    margin-right: 5px;
}

.maximize-button {
    margin-left: 15px;
}

.carousel .control-arrow::before, .carousel.carousel-slider .control-arrow::before {
    margin: 0px !important;
    border-left:none !important;
    border-right:none !important;

}

.carousel .slide {
    height: 207px;
}

.carousel .control-arrow.control-next {
    background-image: url(/static/media/chevron-right.8af14852.svg)  !important;
    background-repeat: no-repeat !important;
    background-size:  50px !important;
    width: 50px;
}
.carousel .control-arrow.control-prev {
    background-image: url(/static/media/chevron-left.305a1841.svg)   !important;
    background-repeat: no-repeat !important;
    background-size:  50px !important;
    width: 50px;
}

.hot-news-wrapper {
    margin-top:50px;
}

.hot-news-wrapper .news-item {
    margin-bottom: 30px !important;
}

.red-separator {
    border-top:2px solid #c12c1a;
    margin-top:30px;
}

.news-item {
    border: none !important;
}

.news-single .card-body {
    padding: 0px;
    padding-right: 15px;
}

.news-item img {
    height: auto;
    width: 100%;
}

.popular-news-head {
    border:1px solid #000;
    position: relative;
}

.popular-news-title {
    position: absolute;
    padding: 10px;
    background-color: #fff;
    color: #000;
    margin-top: -20px;
    margin-left: -10px;
}
.more-popular{
    margin-left: 50px !important;
    right: -2px;
}
.most-popular-wrapper {
    margin-top: 50px;
}

.most-popular-wrapper .card-title {
    font-size: 16px;
    margin-bottom: 0px;
}
.most-popular-wrapper .card-text {
    margin-bottom: 0px;
}

.most-popular-wrapper .card-body {
    padding-top: 0px;
}

.news-item .card-title {
    font-weight: bolder;
    
}

.position-wrapper {
    border-right:2px solid #c12c1a;
}

.parlement-logo, .parlement-count {
    float: left;
    margin-right: 20px;
}

.parlement-logo img {
    width: 100px;
}
.candidate-wrapper {
    float: left;
    width: 50%;
    margin-right: 15px;
    margin-top: 13px;
}
.candidate-wrapper-detail {
    float: left;
    margin-top: -10px;
}
.candidate-name {
    font-weight: 800;
    font-size: 28px;
    margin-bottom: 10px;
    border-bottom: 2px solid #c12c1a;
    
}
.candidate-total-vote {
    font-weight: 800;
    font-size: 60px;
    
}
.parlement-wrapper {
    margin-top: 30px;
}

.parlement-count {
    font-weight: 800;
    font-size: 1.7vh;
    height: 100px;
    
}

.check-image {
    width: 140px;
    position: absolute;
    margin-left: 20px;
    margin-top: -25px;
}

.title-majority {
    
    font-weight: 400;
    font-size: 20px;
    position: absolute;
    margin-left: -168px;
    margin-top: 81px;
}

.tns-controls {
    display: none;
}


.small-box {
    fill: rgb(227, 227, 227);
    stroke: #000;
}

.small-box:hover {
    fill: #10376c !important;
    cursor: pointer;
}

.st1 {
    stroke: #000;
    stroke-width: 0.3px;
    stroke-linejoin: round;
}

.st4 {
    stroke: #000;
    stroke-width: 0.3px;
    stroke-linejoin: round;
}

text {
    fill: #000;
    stroke: none;
    pointer-events: none;
    font-family: 'Gotham';
    font-weight: 200;
    font-size: 14px;
    white-space: pre;
}

.map-navigation-controll {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0px;
    cursor: pointer;
}

.nav-decision-candidate .map-navigation-controll {
    left: 0px !important;

}
.nav-map-zoom-in {
    margin-top: -400px;
    
}
.nav-map-zoom-out {
    margin-top: -350px;
    
}
.nav-map-zoom-reset {
    margin-top: -300px;
    
}
.map-navigation-controll-wrapper {
    position: relative;

}

.map-navigation-controll:hover {
    opacity: 0.8;
}

.desicion-bg-1 {
    background-color: #dcf6fb !important;
}

#svgMalaysia, .map-svg-wrapper, .map-svg-wrapper svg {
    width: 100%;
    height: 800px;
}


.map-hover-info-wrapper {
    background-color: rgb(225,225,225);
    height: 280px;
    width: 400px;
    border-radius: 10px;
    box-shadow: 0 5px 0 #ccc;
    position: fixed;
    padding: 10px;
}

.map-scroll-top img {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    margin-left: 7px;
}
.map-scroll-bottom img {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-left: 7px;
}
.candidate-decision-map-wrapper .map-hover-info-wrapper {
    margin-left: 100px !important;

}

.map-scroll-top, .map-scroll-bottom {
    background-color: #4fa19d;
    width: 40px;
    height: 30px;
    position: absolute;
    color: #fff;
    right: 10px;
    cursor: pointer;

}

.map-scroll-top {
    top: 10px;
}

.map-scroll-bottom {
    bottom: 10px;
}

.map-city-list-wrapper {
    position: absolute;
    width: 300px;
    background-color: #fff;
    padding-bottom: 60px;
    padding-left: 20px;
    right: 8px;
    top: 60px;
    max-height: 754px;
}

.map-scroll-city-wrap {
    max-height: 684px;
    overflow-y: auto;
    margin-top: 36px;
}

.map-scroll-city-wrap::-webkit-scrollbar-track {
    border: 1px solid #000;
    padding: 2px 0;
    background-color: #404040;
  }
  
  .map-scroll-city-wrap::-webkit-scrollbar {
    width: 0px;
  }
  
  .map-scroll-city-wrap::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #737272;
    border: 1px solid #000;
  }

.map-city-body {
    font-weight: bold;
}

.map-city-body a {
    cursor: pointer;
}
.map-city-body a:hover {
    opacity: 0.8;
}

.map-tip-head-title {
    width:60% ;
    text-align: right;
    font-size: 14px;
    padding-top: 11px;
    padding-right: 0px;
}
.flag-wrapper {
    width:40% ;
    padding: 0px;
}

.flag-wrapper img {
    width: 100%;
    padding-top: 3px;
    padding-right: 15px;
}
.map-tip-head-title strong {
    font-size: 20px;
    font-weight: 400;
    font-family:"Gotham";
    padding-right: 10px;
}

.seat-info {
    padding-top: 3px;
    padding-right: 10px;
    font-size: 16px;
    font-weight: 400;
    font-family:"Gotham";
    border-top: 1px solid #000;
}

.party-initial-sym {
    width: 42px;
    height: 42px;
    background-color: #fff;
    border-radius: 100%;
    font-weight: 400;
    font-family:"Gotham";
    font-size: 16px;
    padding-top: 8px;
}

.parlement-seat-wrapper {
    font-size: 16px;
    padding-top: 15px;
    padding-left: 20px;
    padding-bottom: 10px;
}

.seat-info-sub {
    float: left;
    margin-top:8px;
    margin-left: 14px;
    font-weight: 400;
    font-family:"Gotham";
    font-size: 16px;
}

.party-initial-sym {
    float: left;
}

.parlement-seat-wrapper {
    margin-top: 10px;
}

.news-slide-title {
    line-height: 18px;
}



.m-modal-wrapper {
    width: 100%;
    position: absolute;

}

.modal-dialog {
    margin: 0px !important;
    width: 100%;
}

.modal-content {
    border: none !important;
    border-radius: 0px !important;
}

.modal-backdrop  {
    display: none !important;
}

.modal-content {
    height: 100%;
    background-color: rgba(225,225,225,0.95);
    box-shadow: 5px 0px 10px #000;
}

.parlement-wrapper-mobile {
    display: table;
    text-align: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-content: center;
          align-content: center;
}
.parlement-wrapper-mobile img{
    width: 50px;
}

.parlement-wrapper-mobile .parlement-logo {
    margin-right: 0px;
}
.parlement-wrapper-mobile .parlement-count {
    font-size: 50px !important;
    height: auto;
}

.table-mobile {
    background-color: #fff;
}

.modal-title-center {
    font-weight: 800;
    font-size: 20px;
    text-align: center;
}

.close-wrapper {
    height:0px;
	width:0px;
    border-bottom:solid 60px transparent;
	border-left:solid 60px #b0322e;
	border-right:solid 60px transparent;
    cursor: pointer;
    position: absolute;
    z-index: 9999;
}

.close-wrapper span {
    position: absolute;
    font-size: 20px;
    top: 5px;
    font-weight: 600;
    color:#fff !important;
    left: 0px;
    margin-left: -50px;
}

.flag-modal-wrapper .flag-modal {
    width: 100px;
}

.map-parlement-title {
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 20px;
}

.map-parlement-candidate-stat {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.map-parlement-candidate-stat span {
    float: right;
}

.decision-separator {
    width: 3px;
    height: 40px;
    background-color: #dcf6fb;
    position: absolute;
    right: 30px;
    top: -2px;
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);

}

.decision-content-title.with-carrtet-left::before {

    content:"\A";
    border-style: solid;
    border-width: 15px 15px 10px 0;
    border-color:  #d32919 transparent transparent transparent;
    position: absolute;
    left: 0px;
    bottom: -25px;
}

.fakta-menarik-mobile-wrapper {
    border: 1px solid #000;
    border-right: none;
    border-left: none;
}

.fakta-menarik-mobile-overlay {
    margin: 20px 70px;
}

.no-padding {
    padding: 0px  !important;
}


.footer-fakta-menarik {
    border-bottom: 2px solid #000;
}

.title-fakta-menarik {
    border-bottom: 2px solid #000;
}

.faktta-menarik-detail img {
    width: 100%;
}
.faktta-menarik-detail .body-title-2 {
    font-weight: 800;
    font-size: 24px;
    line-height: 25px;
    font-family: 'SF Pro Display';
}

.faktta-menarik-detail {
    padding: 20px;
}
.modal-dialog {
    position:fixed;
    max-width: 100% !important;
    width: 100% !;
    height: 100%;
    bottom:0;
 }  

 .fakta-menarik-title {
    border-bottom: 2px solid #fff;
    margin-bottom: 10px;
 }

 .fakta-menarik-title span {
    position: absolute;
    background-color: #565f5f ;
    color: #fff;
    margin-top: -10px;
    margin-left: -50px;
    padding: 0 10px;
 }

 .front-carausel .fakta-menarik-wrapper{
    padding: 0 50px;
 }

 .footer-2 {
    font-size: 12px;
 }

 .footer-2 span {
    margin-left: -19%;
 }

 .decision-list-overlay {
    position: absolute;
    z-index: 999988;
    top: 0px;
    min-height: 877px;
    width: 100%;
}

.decision2-list-overlay {
    position: absolute;
    z-index: 999988;
    top: 0px;
    min-height: 877px;
    width: 100%;
}

 .decision-list-overlay .decision-content-title{
    width: 100% !important;
 }

 .berita-pru-top {
    background-color: #4aaaa1;
    height: 205px;
    margin-top: 40px;
    width: 33.6%;
    float: left;
    color: #fff;
    font-weight: 800;
    font-size: 40px;
    text-align: center;
    padding-top: 80px;
    margin-left: 1.4%;
 }

 .fw-bold {
    
    font-size: 20px;
 }

 .container {
    margin-top: 12px !important; 
 }

 .dec-hover {
    cursor: pointer !important;
    z-index:9999;
    
 }
 .dec-hover:hover {
    background-color: #2240ae !important;  
    cursor: pointer !important; 
    z-index:9999; 
 }

 .dec-hover.active {
    background-color: #d32919 !important;  
 }

 ol {
    list-style-type: none !important;
 }

 .mobile {
    display: none;
 }
 
 .desktop {
    display: -webkit-flex;
    display: flex;
}
@media only screen and (max-width: 1024px) {
    .hide-mobile {
        display: none;
    }
}
@media only screen and (min-width: 768px) {

    .search-icon-mobile, .malaysia-decides-banner-mobile {
        display: none;
    }

    
    .main-menu-mobile {
        display: none;   
    }

    .modal {
        display: none !important;
    }
}
@media only screen and (max-width: 767px) {
    .container {
        max-width: 100%;
        padding-right: 10px;
        padding-left: 14px;
    }

    .search-icon-mobile {
        display: none;
    }

    .navbar-main {
        padding: 0px !important;
        margin-top: 50px;
    }

    .navbar-main-inner {
        margin-top: 80px;
    }
    .main-logo {
        position: absolute;
        margin-top: -60px;
        margin-left: 15px;
        width: 50px  !important;
    }

    .top-ticker .none {
        display: none;
    }

    .front-carausel {
        display: none;
    }

    .malaysia-decides-banner {
        display: none;
    }
    
    .malaysia-decides-banner-mobile {
        width: 90px;
        float: left;
        margin-top: -60px;
        margin-left: 43%;
    }

    .search-icon-mobile {
        width: 30px;
        float: right;
        margin-top: -50px;
        margin-right: 35px;
    }

     .main-menu-right{
        display: none;
    }

    .main-menu-mobile {
        margin-left: 0px;
        font-weight: 700;
        font-family: Helvetica, sans-serif;
        display: -webkit-flex;
        display: flex;
        grid-gap: 10%;
        gap: 10%;
        padding-left: 0px;
    }

    .main-menu-mobile a {
        font-size: 12px !important;
        padding: 16px 0px !important;
    }

    .main-menu li {
        padding: 0px;
        margin-right: 2px !important;
    }

    .main-menu-desktop {
        display: none;
    }

    .decision-content-title {
        font-size: 16px;
        text-align: center;
        padding-top: 6px !important;
    }

    .decision-content-title2 {
        text-align: center;
        padding: 3px 30px 3px 3px;
    }

    .middle-menu {
        display: none !important;
    }

    .decision-detail-item {
        font-size: 12px !important;
        padding: 5px;
        padding-top: 25px;
    }

    .col{
        padding-top: 8px !important;
        text-align: center;
    }

    .col1 {
        -webkit-flex:1 0 8% !important;
                flex:1 0 8% !important;
        text-align: center;
    }

    .decision-detail-item .decision-count {
        font-size: 14px !important;
    }

    .abbr-item, .abbr-title, .abbr-title, .abbr-count, .abbr-count2  {
        float: left;
    }
    
    .abbr-wrapper {
        padding: 15px 25px;
    }
    
    .abbr-item {
        padding: 10px;
        
    }
    
    .abbr-title {
        font-size: 32px;
        font-weight: 600;
        color: #fff;
        padding:4px 20px;
        text-align: center;
    }


    .abbr-item .abbr-title, .abbr-item {
        font-size: 18px !important;
    }

    .abbr-count  {
        padding: 0px;
        margin-left: 8px;
        color: #000;
        font-weight: 600;
        font-size: 2.2vh !important;
        line-height: 37px !important;
    }

    .abbr-count2  {
        padding: 0px;
        margin-top: -8px;
        margin-left: 20px;
        color: #000;
        font-weight: 600;
        font-size: 50px !important;
        text-align: center;
    }

    .decision-search-wrapper, .middle-menu{
        display: none;
    }

    .decision-content-wrapper {
        margin-top: -10px !important;
    }

    .hide-mobile {
        display: none;
    }

    .main-menu-mobile li ul {
        position: absolute;
        background-color: #dfe0e0;
        display: none;
        z-index: 99;
    }
    
    .main-menu-mobile li ul li a {
        color: #000 !important;
    }
    .main-menu-mobile li ul li {
        width: 30%;
    }
    .main-menu-mobile li ul.full-menu li {
        width: 100%;
    }

    .main-menu-mobile li:hover ul {
        display: block;
    }
    .position-wrapper {
        border-right: none  !important;
    }

    .candidate-decision-map-wrapper {
        display: none;
    }

    .btn-detail-senarai-selangor {
        color: #d70000;
    }

    .parlement-wrapper .parlement-logo img{
        width: 40px;

    }

    .parlement-count {
        font-size:18px;
    }

    .candidate-wrapper {

    }
    
    .candidate-name {
        font-size: 14px;
        line-height: 18px;
        padding-bottom: 2px;
        text-align: left;

    }

    .candidate-total-vote {
        font-size: 20px;
        margin-top: 13px;
        margin-left: -170px;
    }

    .parlement-count {

    }

    .decision-list-overlay {
        display: none;
    }

    .decision2-list-overlay {
        position: relative;
        z-index: 999988;
        top: 0px;
        min-height: 377px;
        width: 100%;
    }

    .check-image {
        width: 42px;
        margin-left: 130px;
        margin-top: -40px;
    }

    .title-majority {
        font-size: 12px;
        margin-left: -60px;
        margin-top: 3vmax;
    }

    .candidate-wrapper-detail {
        float: right;
        margin-top: -10px;
    }   

    .decision-content-title .close {
        margin-right: -25px;
    }

    .decision-content-title .prev {
        margin-top: -2px;
        margin-left: 2px;
    }

    .decision-content-title2 .close {
        margin-right: -25px;
    }

    .decision-content-title2 .prev {
        margin-top: -2px;
        margin-left: 2px;
    }

    .decision-progress-wrapper {
        margin-top: 0px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .progress {
        border-radius: 0px;
        height: 20px;
    }

    .body-title-1 {
        text-align: center;
        margin-top: 20px;
        font-size: 16px;
    }
    
    .body-title-2 {
        text-align: center;
        font-weight: 600;
        font-size: 18px;
    }

    .berita-pru-top {
        background-color: #4aaaa1;
        height: 80px;
        margin-top: 40px;
        width: 97%;
        float: left;
        color: #fff;
        font-weight: 800;
        font-size: 40px;
        text-align: center;
        padding-top: 12px;
        margin-left: 1.4%;
        margin-right: 1.4%;
     }

    .hot-news-wrapper {
        margin-top:-20px;
    }
    
    .section-raya {
        border: 3px solid #c12c1a;
        padding: 0px;
        border-left: none;
        border-right: none;
        margin-top: 0px;
        margin-bottom: 25px;
        text-align: center;
        font-size: 18px;
        font-weight: bolder;
        color: #10376c;
        font-family:"Gotham Black";
    }

    .copyrightWall {
        display: none;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: -webkit-flex;
        display: flex;
    }
  }
